



















































import { Vue, Component } from "vue-property-decorator";
import { Input } from "element-ui";
import { JWTStore } from "@/store/modules";
import { forgotPassword } from "@/store/models/jwt";

@Component({
  components: {
    Input
  }
})
export default class Forgotpassword extends Vue {
  dialogPasswordChangeRequestFormVisible = false;
  payload: forgotPassword = {
    email: ""
  };

  async submit(e: any) {
    // let isValid = await this.$validator.validateAll();
    // if (isValid) {
    await JWTStore.forgotPassword(this.payload);
    this.dialogPasswordChangeRequestFormVisible = true;
    // }
  }

  login() {
    this.$router.push({ name: "Home" }).catch(error => {});
  }
}
