






























































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Validator, Rule } from "vee-validate";

Validator.extend("fileUploaded", {
  getMessage: (field: any) => {
    return "At least one image is required";
  },
  validate: (value: any, args: any[]) => {
    if (args.length > 0) {
      // if file added
      if (args[0] && args[0].length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
} as Rule);

Validator.extend("fileType", {
  getMessage: (field: any) => {
    return "The file must be an jpeg or png image.";
  },
  validate: (value: any, args: any[]) => {
    if (args.length > 0) {
      // if file added
      if (args[0] && args[0].length > 0) {
        let imgType = args[0][0].type;
        if (imgType === "image/jpeg" || imgType === "image/png") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    return false;
  }
} as Rule);

@Component({
  inject: ["$validator"]
})
export default class Registrationthree extends Vue {
  @Prop({ default: {} })
  user!: any;

  registrationCertificate: File[] = [] as File[];
  licenseCertificate: File[] = [] as File[];
  trainingCertificate: File[] = [] as File[];
  othersCertificate: File[] = [] as File[];

  // @Prop({ default: [] as File[] })
  // registrationCertificate!: File[];

  // @Prop({ default: [] as File[] })
  // licenseCertificate!: File[];

  // @Prop({ default: [] as File[] })
  // trainingCertificate!: File[];

  // @Prop({ default: [] as File[] })
  // othersCertificate!: File[];

  date: any = "";
  pickdate = {
    disabledDate(time: any) {
      return time.getTime() > Date.now();
    },
    shortcuts: [
      {
        text: "Today",
        onClick(picker: any) {
          picker.$emit("pick", new Date());
        }
      },
      {
        text: "Yesterday",
        onClick(picker: any) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          picker.$emit("pick", date);
        }
      },
      {
        text: "A week ago",
        onClick(picker: any) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit("pick", date);
        }
      }
    ]
  };

  handleAfterUploadRegistration(file: any, fileList: any[]) {
    this.registrationCertificate = [];
    this.registrationCertificate.push(file.raw);
    this.$emit("registrationCertificate", file.raw);
  }

  handleRemoveRegistration() {
    this.registrationCertificate = [];
    this.$emit("registrationCertificateRemove");
  }

  handleAfterUploadLicense(file: any, fileList: any[]) {
    this.licenseCertificate = [];
    this.licenseCertificate.push(file.raw);
    this.$emit("licenseCertificate", file.raw);
  }

  handleRemoveLicense() {
    this.licenseCertificate = [];
    this.$emit("licenseCertificateRemove");
  }

  handleAfterUploadTraining(file: any, fileList: any[]) {
    this.trainingCertificate = [];
    this.trainingCertificate.push(file.raw);
    this.$emit("trainingCertificate", file.raw);
  }

  handleRemoveTraining() {
    this.trainingCertificate = [];
    this.$emit("trainingCertificateRemove");
  }

  handleAfterUploadOthers(file: any, fileList: any[]) {
    this.othersCertificate = [];
    this.othersCertificate.push(file.raw);
    this.$emit("othersCertificate", file.raw);
  }

  handleRemoveOthers() {
    this.othersCertificate = [];
    this.$emit("othersCertificateRemove");
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }
}
