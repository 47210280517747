var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"registrationThree fullWidth"},[_c('el-form',[_c('el-form-item',{class:{
        'is-error': _vm.vErrors.has(_vm.$t('organizationRegistrationCertificate')),
        'is-required': _vm.checkIfRequired(_vm.$t('organizationRegistrationCertificate'))
      },attrs:{"label":_vm.$t('organizationRegistrationCertificate')}},[_c('div',[_c('br'),_c('el-upload',{ref:"upload",attrs:{"file-list":_vm.user.registrationcertificate,"on-change":_vm.handleAfterUploadRegistration,"on-remove":_vm.handleRemoveRegistration,"limit":1,"list-type":"picture","action":"","auto-upload":false,"accept":"image/jpeg,image/png"}},[(_vm.user.role == 'supplier' && _vm.user.corporation_type != 'Government')?_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('required|image'),expression:"'required|image'"}],attrs:{"slot":"trigger","name":_vm.$t('organizationRegistrationCertificate'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.registrationCertificate),callback:function ($$v) {_vm.registrationCertificate=$$v},expression:"registrationCertificate"}},[_vm._v(" "+_vm._s(_vm.$t("selectFile"))+" ")]):_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('image'),expression:"'image'"}],attrs:{"slot":"trigger","name":_vm.$t('organizationRegistrationCertificate'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.registrationCertificate),callback:function ($$v) {_vm.registrationCertificate=$$v},expression:"registrationCertificate"}},[_vm._v(" "+_vm._s(_vm.$t("selectFile"))+" ")])],1)],1),(_vm.vErrors.has(_vm.$t('organizationRegistrationCertificate')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("organizationRegistrationCertificate")))+" ")]):_vm._e()]),_c('el-form-item',{class:{
        'is-error': _vm.vErrors.has(_vm.$t('organizationRegistrationDate')),
        'is-required': _vm.checkIfRequired(_vm.$t('organizationRegistrationDate'))
      },attrs:{"label":_vm.$t('organizationRegistrationDate')}},[(
          _vm.registrationCertificate.length > 0 ||
            (_vm.user.role == 'supplier' && _vm.user.corporation_type != 'Government')
        )?_c('el-date-picker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticStyle:{"width":"100%"},attrs:{"format":"yyyy-MM-dd","value-format":"yyyy-MM-dd","name":_vm.$t('organizationRegistrationDate'),"type":"date","placeholder":"Pick a Registration date"},model:{value:(_vm.user.registrationdate),callback:function ($$v) {_vm.$set(_vm.user, "registrationdate", $$v)},expression:"user.registrationdate"}}):_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"yyyy-MM-dd","value-format":"yyyy-MM-dd","name":_vm.$t('organizationRegistrationDate'),"type":"date","placeholder":"Pick a Registration date"},model:{value:(_vm.user.registrationdate),callback:function ($$v) {_vm.$set(_vm.user, "registrationdate", $$v)},expression:"user.registrationdate"}}),(_vm.vErrors.has(_vm.$t('organizationRegistrationDate')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("organizationRegistrationDate")))+" ")]):_vm._e()],1),_c('el-form-item',{class:{
        'is-error': _vm.vErrors.has(_vm.$t('seedCompanyLicense')),
        'is-required': _vm.checkIfRequired(_vm.$t('seedCompanyLicense'))
      },attrs:{"label":_vm.$t('seedCompanyLicense')}},[_c('div',[_c('br'),_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"file-list":_vm.user.license,"on-change":_vm.handleAfterUploadLicense,"on-remove":_vm.handleRemoveLicense,"limit":1,"list-type":"picture","action":"","auto-upload":false,"accept":"image/jpeg,image/png"}},[(_vm.user.role == 'supplier' && _vm.user.corporation_type != 'Government')?_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('required|image'),expression:"'required|image'"}],attrs:{"slot":"trigger","name":_vm.$t('seedCompanyLicense'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.licenseCertificate),callback:function ($$v) {_vm.licenseCertificate=$$v},expression:"licenseCertificate"}},[_vm._v(" "+_vm._s(_vm.$t("selectFile"))+" ")]):_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('image'),expression:"'image'"}],attrs:{"slot":"trigger","name":_vm.$t('seedCompanyLicense'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.licenseCertificate),callback:function ($$v) {_vm.licenseCertificate=$$v},expression:"licenseCertificate"}},[_vm._v(" "+_vm._s(_vm.$t("selectFile"))+" ")])],1)],1),(_vm.vErrors.has(_vm.$t('seedCompanyLicense')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("seedCompanyLicense")))+" ")]):_vm._e()]),_c('el-form-item',{class:{
        'is-error': _vm.vErrors.has(_vm.$t('licenseIssuedDate')),
        'is-required': _vm.checkIfRequired(_vm.$t('licenseIssuedDate'))
      },attrs:{"label":_vm.$t('licenseIssuedDate')}},[_c('el-col',{attrs:{"span":24}},[(
            _vm.licenseCertificate.length > 0 ||
              (_vm.user.role == 'supplier' && _vm.user.corporation_type != 'Government')
          )?_c('el-date-picker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticStyle:{"width":"100%"},attrs:{"format":"yyyy-MM-dd","value-format":"yyyy-MM-dd","name":_vm.$t('licenseIssuedDate'),"type":"date","placeholder":"Pick a license issue date"},model:{value:(_vm.user.licenseissueddate),callback:function ($$v) {_vm.$set(_vm.user, "licenseissueddate", $$v)},expression:"user.licenseissueddate"}}):_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"name":_vm.$t('licenseIssuedDate'),"type":"date","format":"yyyy-MM-dd","value-format":"yyyy-MM-dd","placeholder":"Pick a license issue date"},model:{value:(_vm.user.licenseissueddate),callback:function ($$v) {_vm.$set(_vm.user, "licenseissueddate", $$v)},expression:"user.licenseissueddate"}})],1),(_vm.vErrors.has(_vm.$t('licenseIssuedDate')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("licenseIssuedDate")))+" ")]):_vm._e()],1),_c('el-form-item',{class:{
        'is-error': _vm.vErrors.has(_vm.$t('trainingCertificate')),
        'is-required': _vm.checkIfRequired(_vm.$t('trainingCertificate'))
      },attrs:{"label":_vm.$t('trainingCertificate')}},[_c('div',[_c('br'),_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"file-list":_vm.user.trainingcertificate,"on-change":_vm.handleAfterUploadTraining,"on-remove":_vm.handleRemoveTraining,"limit":1,"list-type":"picture","action":"","auto-upload":false,"accept":"image/jpeg,image/png"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('image'),expression:"'image'"}],attrs:{"slot":"trigger","name":_vm.$t('trainingCertificate'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.trainingCertificate),callback:function ($$v) {_vm.trainingCertificate=$$v},expression:"trainingCertificate"}},[_vm._v(" "+_vm._s(_vm.$t("selectFile"))+" ")])],1)],1),(_vm.vErrors.has(_vm.$t('trainingCertificate')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("trainingCertificate")))+" ")]):_vm._e()]),_c('el-form-item',{class:{
        'is-error': _vm.vErrors.has(_vm.$t('other')),
        'is-required': _vm.checkIfRequired(_vm.$t('other'))
      },attrs:{"label":_vm.$t('other')}},[_c('div',[_c('br'),_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"file-list":_vm.user.otherdocument,"on-change":_vm.handleAfterUploadOthers,"on-remove":_vm.handleRemoveOthers,"limit":1,"list-type":"picture","action":"","auto-upload":false,"accept":"image/jpeg,image/png"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('image'),expression:"'image'"}],attrs:{"slot":"trigger","name":_vm.$t('other'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.othersCertificate),callback:function ($$v) {_vm.othersCertificate=$$v},expression:"othersCertificate"}},[_vm._v(" "+_vm._s(_vm.$t("selectFile"))+" ")])],1)],1),(_vm.vErrors.has(_vm.$t('other')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("other")))+" ")]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }