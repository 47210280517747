










































































import { Vue, Component } from "vue-property-decorator";
import { Input } from "element-ui";
import { JWTStore } from "@/store/modules";
import { userCreate, userInfo } from "@/store/models/jwt";
import { eventHub } from "@/utils/eventHub";
import FacebookLogin from "@/views/Authscreen/FacebookLogin.vue";
import GoogleLogin from "@/views/Authscreen/GoogleLogin.vue";

@Component({
  components: {
    Input,
    FacebookLogin,
    GoogleLogin
  },
  metaInfo: {
    title: "User Registration",
    titleTemplate: "%s - SQCC",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "User Registration",
        vmid: "registration"
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  }
})
export default class RegisterUser extends Vue {
  labelPosition: string = "top";
  userexists: boolean = false;
  loading: boolean = false;
  payload: userCreate = {
    username: "",
    name: "",
    password: "",
    address: ""
  };

  async checkemailexists() {
    this.loading = true;
    await JWTStore.userexists(this.payload.username);
    this.userexists = JWTStore.UserExists;
    this.loading = false;
  }

  registerScreen() {
    this.$router
      .push({
        name: "SeedCompanyRegister",
        params: { lang: this.$i18n.locale }
      })
      .catch(error => {});
  }

  async register(e: any) {
    let isValid = await this.$validator.validateAll();

    if (isValid) {
      let a = await JWTStore.register(this.payload);
      this.$router.push("/en").catch(error => {});
      this.$notify.success("An email is sent to your email for activating your account");
    }
  }

  emitGloballogin() {
    eventHub.$emit("loginClick");
  }

  async social_auth(credentials: any) {
    await JWTStore.social_auth(credentials);
  }
}
