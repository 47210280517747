
























































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyUser } from "@/store/models/user";
import { MetaStore, UserStore } from "@/store/modules";
import { Validator, Rule } from "vee-validate";

Validator.extend("fileUploaded", {
  getMessage: (field: any) => {
    return "At least one image is required";
  },
  validate: (value: any, args: any[]) => {
    if (args.length > 0) {
      // if file added
      if (args[0] && args[0].length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
} as Rule);

Validator.extend("fileType", {
  getMessage: (field: any) => {
    return "The file must be an jpeg or png image.";
  },
  validate: (value: any, args: any[]) => {
    if (args.length > 0) {
      // if file added
      if (args[0] && args[0].length > 0) {
        let imgType = args[0][0].type;
        if (imgType === "image/jpeg" || imgType === "image/png") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    return false;
  }
} as Rule);

@Component({
  metaInfo: {
    title: "Register Company",
    titleTemplate: "%s - SQCC",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Company Registration",
        vmid: "registration"
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  }
})
export default class RegisterseedComp extends Vue {
  companyreg: CompanyUser = {} as CompanyUser;
  companyId: number = 0;
  registrationCertificate: File[] = [] as File[];
  licenseCertificate: File[] = [] as File[];
  trainingCertificate: File[] = [] as File[];
  othersCertificate: File[] = [] as File[];
  image: any = { name: "", url: "" };
  imageDialog: boolean = false;
  loading: boolean = false;
  registering: boolean = false;
  imageTitle = "";
  approveText = "Approve";
  organization = [
    { label: "Government", value: "Government" },
    { label: "Private", value: "Private" },
    { label: "NGO/INGO", value: "NGO/INGO" },
    { label: "Research Center", value: "Research Center" },
    { label: "Cooperative", value: "Cooperative" },
    { label: "Others", value: "Others" }
  ];

  async registerCompany() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      this.registering = true;
      this.companyreg.username = this.companyreg.email;

      let company = new FormData();
      for (let key in this.companyreg as any) {
        if (
          key != "companyRegistrationCertificate" &&
          key != "registrationcertificate" &&
          key != "license" &&
          key != "otherdocument" &&
          key != "trainingcertificate"
        ) {
          if ((this.companyreg as any)[key] && (this.companyreg as any)[key] != null) {
            company.append(key, (this.companyreg as any)[key]);
          }
        }
      }
      if (this.companyreg.name && this.companyreg.name != null) {
        company.append("company_name", this.companyreg.name);
      }
      if (this.companyreg.nepname && this.companyreg.nepname != null) {
        company.append("company_nepname", this.companyreg.nepname);
      }
      company.append("latitude", "");
      company.append("longitude", "");

      for (let file of this.registrationCertificate) {
        company.append("registrationcertificate", file);
      }
      for (let file of this.licenseCertificate) {
        company.append("license", file);
      }
      for (let file of this.trainingCertificate) {
        company.append("trainingcertificate", file);
      }
      for (let file of this.othersCertificate) {
        company.append("otherdocument", file);
      }

      console.log(this.companyreg);

      let error: boolean = false;
      this.companyreg = await UserStore.EditCompanyProfile(company).catch(reason => {
        error = true;
      });
      this.registering = false;

      if (!error) {
        this.companyId = this.companyreg.id;
        this.$notify.success("Saved Successfully");
      }
    }
  }

  get provinces() {
    return MetaStore.Province;
  }

  handleRegistrationPreview(file: any) {
    this.image = file;
    if (this.image != undefined) {
      this.imageTitle = "Registration Certificate";
      this.imageDialog = true;
    }
  }

  handleLicensePreview(file: any) {
    this.image = file;
    if (this.image != undefined) {
      this.imageTitle = "Seed Company License";
      this.imageDialog = true;
    }
  }

  handleTrainingPreview(file: any) {
    this.image = file;
    if (this.image != undefined) {
      this.imageTitle = "Training Certificate";
      this.imageDialog = true;
    }
  }

  handleOtherDocumentPreview(file: any) {
    this.image = file;
    if (this.image != undefined) {
      this.imageTitle = "Other Documents";
      this.imageDialog = true;
    }
  }

  get districts() {
    let district = MetaStore.District;
    if (this.companyreg.provinceid) {
      district = district.filter(x => x.provinceid == this.companyreg.provinceid);
    } else {
      district = [];
    }
    return district;
  }

  get palikas() {
    let palika = MetaStore.Palika;
    if (this.companyreg.districtid) {
      palika = palika.filter(x => x.districtid == this.companyreg.districtid);
    } else {
      palika = [];
    }
    return palika;
  }

  async created() {
    if (this.$route.query.companyId) {
      this.companyId = Number(this.$route.query.companyId);
      this.loading = true;
      this.companyreg = await UserStore.getCompanyByUserId(this.companyId);
      this.loading = false;
    }
    MetaStore.getAllProvince();
    MetaStore.getAllDistrict();
    MetaStore.getAllPalika();
  }

  async approve() {
    if (this.companyId != undefined && this.companyId != 0) {
      await UserStore.approveCompanyById({
        id: this.companyId,
        status: true
      });
      this.$notify.success("Organization is being approved. This may take some time.");
      this.approveText = "Approving";
    }
  }

  handleAfterUploadRegistration(file: any, fileList: any[]) {
    this.registrationCertificate = [];
    this.registrationCertificate.push(file.raw);
  }

  handleAfterUploadLicense(file: any, fileList: any[]) {
    this.licenseCertificate = [];
    this.licenseCertificate.push(file.raw);
  }

  handleRemoveRegistration(file: any, fileList: any[]) {
    this.registrationCertificate = [];
  }

  handleRemoveLicense(file: any, fileList: any[]) {
    this.licenseCertificate = [];
  }

  handleAfterUploadTraining(file: any, fileList: any[]) {
    this.trainingCertificate = [];
    this.trainingCertificate.push(file.raw);
  }

  handleAfterUploadOthers(file: any, fileList: any[]) {
    this.othersCertificate = [];
    this.othersCertificate.push(file.raw);
  }

  back() {
    if (this.$route.query.activeTab) {
      this.$router
        .push({
          name: "UserList",
          query: { activeTab: String(this.$route.query.activeTab) }
        })
        .catch(error => {});
    } else {
      this.$router
        .push({
          name: "Adminmenu"
        })
        .catch(error => {});
    }
  }
}
