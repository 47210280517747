import { render, staticRenderFns } from "./Chooserole.vue?vue&type=template&id=af04f97a&scoped=true&"
import script from "./Chooserole.vue?vue&type=script&lang=ts&"
export * from "./Chooserole.vue?vue&type=script&lang=ts&"
import style0 from "./Chooserole.vue?vue&type=style&index=0&id=af04f97a&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af04f97a",
  null
  
)

export default component.exports