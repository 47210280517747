

























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  metaInfo: {
    title: "Registration",
    titleTemplate: "%s - SQCC",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "User Registration",
        vmid: "registration"
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  }
})
export default class Chooserole extends Vue {
  role: string = "";
  proceesrole() {
    if (this.role == "") {
      this.$message("Please select at least one option");
      return;
    } else if (this.role == "producer") {
      this.$router
        .push({
          name: "Registersteps",
          params: { lang: this.$i18n.locale }
        })
        .catch(error => {});
    } else {
      this.$router
        .push({
          name: "Register",
          params: { lang: this.$i18n.locale }
        })
        .catch(error => {});
    }
  }
}
