














import { Vue, Component } from "vue-property-decorator";
import { Input } from "element-ui";
import { JWTStore } from "@/store/modules";
import { Activate } from "@/store/models/jwt";
import { eventHub } from "@/utils/eventHub";

@Component({
  components: {
    Input
  }
})
export default class activate extends Vue {
  payload = {} as Activate;

  async created() {
    this.payload.uid = this.$route.params.uid;
    this.payload.token = this.$route.params.token;
    await JWTStore.activate(this.payload);
  }

  emitGloballogin() {
    eventHub.$emit("loginClick");
  }
}
